import { isSubscriptionDetailType, isSubscriptionStepId, type SubscriptionDetail } from '@orus.eu/dimensions'
import { useParams } from '@tanstack/react-router'
import { memo, useMemo } from 'react'
import { NotFound } from '../../organisms/not-found'
import { SubscriptionPageV2 } from './subscription-v2-page'
import { SubscriptionV2StepPageLoader } from './subscription-v2-step-page-loader'

export default memo(function SubscriptionV2StepDetailPage() {
  const { subscriptionId, stepId, detailId, detailType } = useParams({
    from: '/subscribe/$subscriptionId/$stepId/$detailType/$detailId',
  })

  const detail = useMemo<SubscriptionDetail | undefined>(
    () => (isSubscriptionDetailType(detailType) ? { id: detailId, type: detailType } : undefined),
    [detailId, detailType],
  )

  if (!isSubscriptionStepId(stepId) || !detail) {
    return <NotFound />
  }

  return (
    <SubscriptionPageV2>
      <SubscriptionV2StepPageLoader requestedSubscriptionId={subscriptionId} requestedStepId={stepId} detail={detail} />
    </SubscriptionPageV2>
  )
})
